"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'YOKOHAMA Hack!',
    organizationId: 'NSwesKTYiQwJjMf0i4Zx',
    version: '1.0.0',
    ionicAppId: '',
    applicationKey: 'yokohamahack',
    applicationType: 'standalone',
    artistId: 'V4EOCOiwdnXiWnwQrtT5TKkD3tc2',
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.yokohamahack',
        appId: '',
    },
    android: {
        bundleId: 'com.utoniq.yokohamahack',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: false,
            trade: false,
            membership: false,
            thread: false,
            prepaidPoint: null,
            gacha: true,
            nft: false,
            liveStream: false,
            item: true,
            store: true,
            campaign: false,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: false,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: [],
        artistTabs: ['home', 'posts', 'collections', 'gachas', 'myProfile'],
    },
    deeplinkUrl: 'https://yokohamahack.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/3562308',
    storagePath: 'yokohamahack',
    dynamicLinkPrefix: 'yokohamahack',
    deeplinkProd: 'yokohamahack.utoniq.com',
};
exports.default = appConfig;
